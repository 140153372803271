import * as React from 'react';
import poolData from '../services/config';

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

class Login extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {            
            email: '',
            password: ''
        }
    }

    componentDidMount() {
        document.title = `Myanmar Lyrics | Login`;
    }

    handleChange = (event: any) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

    login = () => {
        const _this = this;

        const email = _this.state.email;
        const password = _this.state.password;

        if(!(email && password))
        {
            alert('missing fields on the form')
            return;
        }

        let authenticationData = {
            Username: email,
            Password: password,
        };
        let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
            authenticationData
        );
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let userData = {
            Username: email,
            Pool: userPool,
        };
        let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function(result) {                

                // let groups = result.getAccessToken().decodePayload()['cognito:groups'];

                // let isAdminFunc = function(list: any) {
                //     return list?.some(function (item: any) {
                //         if(item === 'admin') {
                //             return true;
                //         } 
                //         return false;
                //     });
                // }

                // let isAdmin = isAdminFunc(groups);

                // let accessToken = result.getAccessToken().getJwtToken();
                // console.log('token: ' + accessToken);

                // cognitoUser.getUserData(function (err, userData) {
                //     if(err) {
                //         console.log(err.message || JSON.stringify(err));
                //         return;
                //     }
                //     console.log(userData);
                // })
                document.location.href="/";
            },
        
            onFailure: function(err) {
                alert(err.message || JSON.stringify(err));
            },
        });
    }

    render() {
        let body = <div className="container center-block" 
                        style={{paddingTop: '30px',width: '400px'}}>
                        <h1>Login</h1>
                        <form>
                        <div className="form-group">
                            <input
                                    onChange={this.handleChange}
                                    required
                                    name="email"
                                    placeholder="Email" 
                                    className="form-control"
                                    id="username" 
                                    type="text" 
                                    maxLength={120}/>
                            </div>
                            <div className="form-group">
                                <input
                                    onChange={this.handleChange}
                                    required
                                    name="password"
                                    placeholder="Password" 
                                    className="form-control" 
                                    id="password" 
                                    type="password" 
                                    maxLength={20}/>
                            </div>
                            <button 
                                type="button" 
                                className="btn btn-primary" 
                                onClick={this.login}>
                                    Login
                            </button>
                        </form>
                    </div>

        return (
            <div>
                {body}
            </div>
        );
    }
}

export default Login