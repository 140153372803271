import React from 'react';
import * as Consts from '../services/constants';

import styled from 'styled-components';
import GenresMultiSelect from './genresMultiselect';
import ArtistMultiSelect from './artistsMultiselect';
import CognitoService from '../services/cognitoService';
import Loading from './loading';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const axios = require('axios').default;

const Styles = styled.div`
  color: black;
`

class CreateSong extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            public: true,
            errors: '',
            loading: false,
            showToast: false,
        }
    }

    componentDidMount() {        
    }

    showToast = () => {
        this.setState({ showToast: !this.state.showToast })
    }

    closeToast = (arg: any) => {
        this.setState({ showToast: arg })
    }

    handleChange = (event: any) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

    handleSubmit = (event: any) => {

        event.preventDefault();
        const _this = this;

        const userid = CognitoService.getUserId();
        if(!userid) {
            alert('you need to login to upload');
            return;
        }

        let error = false;
        error = this.checkerror(error);
        if(error) return;

        let input: any = document.querySelector('input[type="file"]')

        let formData = new FormData();

        formData.append('file', input?.files[0])
        formData.append('title', _this.state.title);
        formData.append('titleInUnicode', _this.state.titleInUnicode);
        formData.append('titleInZawGyi', _this.state.titleInZawGyi);
        // without stringify you will get [object object] string on api
        // this only happens with formData. json postbody is fine.
        // json postbody can't be used here as sending form only works with formdata
        formData.append('artists', JSON.stringify(_this.state.artists)); 
        formData.append('album', _this.state.album);
        formData.append('lyrics', _this.state.lyrics);
        formData.append('genres', _this.state.genres);
        formData.append('link', _this.state.link);
        formData.append('public', _this.state.public);
        formData.append('username', userid);

        this.setState({ loading: true });

        axios.post(Consts.apiUrl + 'admin/create', formData)
        .then(function () {
            _this.setState({ loading: false });
            _this.props.history.push('/');
        })
        .catch(function (error:any) {
            _this.setState({ loading: false });
            console.log(error);
            _this.showToast();
            _this.setState({message: 'error happened.'});
        });
    }

    private checkerror(error: boolean) {
        const _this = this;
        if (!_this.state.title) {
            _this.setState({ errors: 'title is needed' });
            error = true;
        }
        // if (!(_this.state.artists && _this.state.artists.length > 0)) {
        //     _this.setState({ errors: 'artists is needed' });
        //     error = true;
        // }
        // if(!_this.state.album) {
        //     _this.setState({ errors: 'album is needed' });
        //     error = true;
        // }
        // if(!_this.state.lyrics) {
        //     _this.setState({ errors: 'lyrics is needed' });
        //     error = true;
        // }
        // if(!_this.state.genres) {
        //     _this.setState({ errors: 'genres is needed' });
        //     error = true;
        // }
        // if(!_this.state.link) {
        //     _this.setState({ errors: 'link is needed' });
        //     error = true;
        // }
        return error;
    }

    handleGenresSelect = (arg: any) => {
        const _this = this;
        _this.setState({genres: arg})
    }

    handleArtistsSelect = (arg: any) => {
        const _this = this;
        _this.setState({artists: arg})
    }

    render() {
        return (
            <div>

                {/* <ToastComponent
                    closeToast={this.closeToast}
                    showToast={this.state.showToast} 
                    message={this.state.message}>                    
                </ToastComponent> */}
                
                <h1>Add new song</h1>

                <Loading loading={this.state.loading}></Loading>

                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="title"
                            onChange={this.handleChange}
                            placeholder="english or burglish title">
                        </input>
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="titleInUnicode"
                            onChange={this.handleChange}
                            placeholder="unicode title">
                        </input>
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="titleInZawGyi"
                            onChange={this.handleChange}
                            placeholder="zawgyi title">
                        </input>
                    </div>
                    <div className="form-group">
                        <ArtistMultiSelect onSelect={this.handleArtistsSelect}></ArtistMultiSelect>
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="album"
                            onChange={this.handleChange}
                            placeholder="album">
                        </input>
                    </div>
                    <div className="form-group">
                        <GenresMultiSelect onSelect={this.handleGenresSelect}></GenresMultiSelect>
                    </div>
                    <div className="form-group">
                        <a href='https://support.google.com/youtube/answer/171780?hl=en-GB' target='_blank'>click here</a> for how to get youtube embed link
                        <input
                            className="form-control"
                            type="text"
                            name="link"
                            onChange={this.handleChange}
                            placeholder="Youtube embed link">
                        </input>
                    </div>

                    <div className="form-group">
                        <label>Lyrics as image</label>
                        <input
                            className="form-control"
                            type="file"
                            name="file"
                        >
                        </input>
                    </div>

                    <div className="form-group">
                        <label>Lyrics as texts</label>
                        <Styles>
                            {/* <Suspense fallback={<div>Loading...</div>}> */}
                                <CKEditor
                                    placeholder='hasdfasdf'
                                    editor={ ClassicEditor }
                                    onInit={ (editor: any) => {
                                        // this.setState({ lyrics: '<p>lyrics</p>' });
                                        // You can store the "editor" and use when it is needed.
                                        // console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={ ( event: any, editor: { getData: () => any; } ) => {
                                        const data = editor.getData();
                                        // console.log( { event, editor, data } );
                                        this.setState({ lyrics: data });
                                    } }
                                    onBlur={ ( event: any, editor: any ) => {
                                        // console.log( 'Blur.', editor );
                                    } }
                                    onFocus={ ( event: any, editor: any ) => {
                                        // console.log( 'Focus.', editor );
                                    } }
                                >
                                </CKEditor>
                            {/* </Suspense> */}
                        </Styles>
                    </div>
                    <div className="form-group">
                        <select 
                            name="public"
                            value={this.state.public} 
                            onChange={this.handleChange}>
                            <option value='true'>public</option>
                            <option value='false'>private</option>
                        </select>
                    </div>
                    <div className="form-group">
                        {this.state.errors}
                    </div>
                    
                    <input 
                        type="submit" 
                        value="Submit" 
                        className="btn btn-light" 
                    />
                </form>
            </div>
        );
    }
}

export default CreateSong