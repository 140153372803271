import React from "react";
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`

`;

class Loading extends React.Component<any, any> {
    constructor(props: any) {
      super(props);
      this.state = {
        loading: false
      };
    }

    static getDerivedStateFromProps(props: any, state: any) {

      if(props.loading !== state.loading) {
        return {
          loading: props.loading
        }
      }

      return null;
    }
  
    render() {
      return (
        <div>
          <BeatLoader
            css={override}
            size={15}
            color={"#185F51"}
            loading={this.state.loading}
          />
        </div>
      );
    }
  }

  export default Loading;