import * as React from 'react';
import * as Consts from '../services/constants';

class SongList extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            songs: []
        }
    }

    componentDidMount() {
        fetch(`${Consts.apiUrl}utility/getall`)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({ 
                    songs: result
                });
            }
        )
    }

    Delete = (id: any) => {

        let isConfirm: any = window.confirm("are you sure?");

        if(isConfirm == true) {
            fetch(`${Consts.apiUrl}utility/deleteone?id=${id}`)
            .then(
                (result) => {
                    window.location.reload();
                }
            )
        }
    }

    render() {
        const _this = this;

        const header = (
            <div style={styles.song}>
                <div style={styles.songtitle}>
                    <span>
                        title
                    </span>
                </div>
                <div style={styles.songtitle}>
                    <span>
                        unicode title
                    </span>
                </div>
                <div style={styles.songtitle}>
                    <span>
                        album title
                    </span>
                </div>
                <div style={styles.songtitle}>
                    <span>
                        album unicode
                    </span>
                </div>
                <div>
                    <span>
                        public
                    </span>
                </div>
                <div style={styles.songtitle}>
                </div>
            </div>
        )

        let songs = _this.state.songs?.map(function (item: any) {
            return (
                <div 
                    key={item.id} 
                    style={styles.song}>
                        
                    <div style={styles.songtitle}>
                        <span
                            >{item.title}
                        </span>
                    </div>
                    <div style={styles.songtitle}>
                        <span
                            >{item.titleInUnicode}
                        </span>
                    </div>
                    <div style={styles.songtitle}>
                        <span
                            >{item.album?.title}
                        </span>
                    </div>
                    <div style={styles.songtitle}>
                        <span
                            >{item.album?.titleInUnicode}
                        </span>
                    </div>
                    <div>
                        <span>
                            {item.public.toString()}
                        </span>
                    </div>
                    <div style={styles.delete}>
                    <button
                        className="btn btn-light"
                        onClick={() => _this.Delete(item.id)}
                    >
                        delete
                    </button>
                    </div>
                    <div style={styles.edit}>
                    <a
                        className="btn btn-light"
                        href={`/song/edit/${item.id}`}
                    >
                        edit
                    </a>
                    </div>
                </div>
            )
        });

        if(_this.state.songs && _this.state.songs.length === 0)
        {            
            return (
                <div>
                    No songs
                </div>
            );
        }

        return (
            <div style={styles.container}>
                <h1>All Songs</h1>
                {songs.length} songs
                {header}
                {songs}
            </div>
        );
    }
}

export default SongList

const styles = {
    container: {
        width: '100%',
        lineHeight: '20px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,
    song: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        border: '1px solid lightgrey',
        padding: '3px',
        margin: '2px',
    } as React.CSSProperties,
    songtitle: {
        width: '200px',
    } as React.CSSProperties,
    delete: {

    } as React.CSSProperties,
    edit: {

    } as React.CSSProperties,
}