import * as React from 'react';
import * as Consts from '../services/constants';
import styled from 'styled-components';

const Styles = styled.div`
`

class Searches extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            searches: []
        }
    }

    componentDidMount() {
        const _this = this;
        let queryUrl = `${Consts.apiUrl}search/getsearches`;
        fetch(queryUrl)
        .then(res => res.json())
        .then(
            (result) => {
                _this.setState({ searches: result })
            }
        )
    }

    render() {
        let searches = this.state.searches?.map(function (item: any) {

            let date;
            let searchedAt;
            if(item.dateTimeCreated)
            {
                date = new Date(item.dateTimeCreated);
                searchedAt = `searched at`;
            }
            return (
                <div key={item.id}>
                    <b>{item.term}</b> {searchedAt} {date?.toLocaleString()}
                </div>
            )
        });

        return (    
            <Styles>
                <h1>Searches</h1>
                {searches && searches.length > 0 ? searches : 'no searches'}
            </Styles>
        );
    }
}

export default Searches