import * as React from 'react';
import * as Consts from '../services/constants';

import Select from 'react-select';

class ArtistMultiSelect extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            selectedOption: null,
            artists: []
        }
    }

    static getDerivedStateFromProps(props: any, state: any) {
        const preselectedValues = props.options;
        
        if(!preselectedValues) return null;

        const optionsValues = ArtistMultiSelect.arrayToOptions(preselectedValues);

        if(optionsValues && optionsValues.length > 0) {

            return {
                selectedOption: optionsValues,
            }
        }

        return null;
    }

    static arrayToOptions(arrayOfStrings: any): any {

        let options: any = [];

        arrayOfStrings?.forEach(function (item: any) {
            options.push({ value: item.id, label: ArtistMultiSelect.capitalizeFirstLetter(item.nameInBurglish) });
        });

        return options;
    }

    static capitalizeFirstLetter(item: string): string {
        if(!item) return item;
        return item.charAt(0).toUpperCase() + item.slice(1);
    }

    componentDidMount() {
        fetch(Consts.apiUrl + 'artist/getall')
        .then(res => res.json())
        .then(
            (result) => {
                let artistsOptions: any = [];
                result.forEach(function (item: any) {
                    artistsOptions.push({ value: item.id, label: item.nameInBurglish });
                });

                this.setState({ 
                    artists: artistsOptions
                });
            }
        )
    }

    handleChange = (newValue: any, actionMeta: any) => {        
        // console.group('Value Changed');
        // console.log(`action: ${actionMeta.action}`);
        // console.groupEnd();

        // actions: remove-value, select-option, create-option, clear

        // if(actionMeta.action === "create-option")
        // {
            // console.log('a new artist')
        // }

        // set the state so select displays correct value
        this.setState({
            selectedOption: newValue
        });

        let artists: any = [];
        newValue?.forEach((element: any) => {
            artists.push({ id: element.value, nameInBurglish: element.label });            
        });

        //this is to pass up to the parent component
        this.props.onSelect(artists);
    };

    render() {
        const _this = this;
        return (
            <div style={{color: 'hsl(0,0%,50%)'}}>
                {/* <CreatableSelect  api can't handle creating new artist this way */}
                <Select                
                    isMulti
                    value={this.state.selectedOption}
                    onChange={_this.handleChange}
                    options={_this.state.artists}
                    placeholder='artists in burglish. use Edit artists form to add unicode and zawgyi.'
                />
            </div>
        );
    }
}

export default ArtistMultiSelect