import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Login from './components/login';
import Manage from './components/manage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Switch>
            <Route path={`/login`} component={Login}></Route>
            <Route path={`/`} component={Manage} ></Route>
          </Switch>
          </BrowserRouter>
    </div>
  );
}

export default App;
