import * as React from 'react';
import * as Consts from '../services/constants';
const axios = require('axios').default;

class ArtistList extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            artists: []
        }
    }

    componentDidMount() {
        fetch(`${Consts.apiUrl}admin/getallartists`)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    artists: result
                });
            }
        )
    }

    Delete = (id: any) => {

        let isConfirm: any = window.confirm("are you sure?");

        if(isConfirm == true) {

            axios.post(`${Consts.apiUrl}artist/delete?id=${id}`)
            .then(function (response: any) {
                window.location.reload();
            })
            .catch(function (error:any) {
                console.log(error);
            });
        }
    }

    render() {
        const _this = this;

        let artists = _this.state.artists?.map(function (item: any) {
            return (
                <div
                    key={item.id}
                    style={styles.row}>

                    <div style={styles.column}>
                        <span
                            >{item.nameInBurglish}
                        </span>
                    </div>
                    <div style={styles.column}>
                        <span
                            >{item.nameInUnicode}
                        </span>
                    </div>
                    <div style={styles.column}>
                        <span
                            >{item.type}
                        </span>
                    </div>
                    <div style={styles.delete}>
                    <button
                        className="btn btn-light"
                        onClick={() => _this.Delete(item.id)}
                    >
                        delete
                    </button>
                    </div>
                    <div style={styles.edit}>
                    <a
                        className="btn btn-light"
                        href={`/artist/edit/${item.id}`}
                    >
                        edit
                    </a>
                    </div>
                </div>
            )
        });

        if(_this.state.artists && _this.state.artists.length === 0)
        {
            return (
                <div>
                    No artists
                </div>
            );
        }

        return (
            <div style={styles.container}>
                <h1>All Artists</h1>
                {artists}
            </div>
        );
    }
}

export default ArtistList

const styles = {
    container: {
        width: '100%',
        lineHeight: '20px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        border: '1px solid lightgrey',
        padding: '3px',
        margin: '2px',
    } as React.CSSProperties,
    column: {
        width: '200px',
    } as React.CSSProperties,
    delete: {

    } as React.CSSProperties,
    edit: {

    } as React.CSSProperties,
}