import React, { Suspense } from 'react';
import * as Consts from '../services/constants';

import styled from 'styled-components';
import GenresMultiSelect from './genresMultiselect';
import ArtistMultiSelect from './artistsMultiselect';
import CognitoService from '../services/cognitoService';
import LyricsImage from './lyricsImage';
import Loading from './loading';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// const CKEditor = React.lazy(() => import('@ckeditor/ckeditor5-react'));

const axios = require('axios').default;

const Styles = styled.div`
  color: black;
`

class EditSong extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            songid : props.match.params.id,
            loading: false,
            album: {},
            song: null
        }
    }

    componentDidMount() {
        const _this = this;
        if(this.state.songid) {
            let queryUrl = `${Consts.apiUrl}admin/getsongbyid?id=${_this.state.songid}`;
            fetch(queryUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    _this.setState({ song: result });
                    return result;
                }
            )
            .then(
                (result) => {
                    _this.setState({ ...result })
                }
            )
        }
    }

    handleGenresSelect = (arg: any) => {
        const _this = this;
        _this.setState({genres: arg});
    }

    handleArtistsSelect = (arg: any) => {
        const _this = this;
        _this.setState({ artists: arg });
    }

    handleChange = (event: any) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({
            [nam]: val
        });
    }

    handleAlbumTitleInBurglishChange = (event: any) => {

        let newValue = this.state.album ? this.state.album : {};
        newValue.titleInBurglish = event.target.value;

        this.setState({
            album: newValue
        })
    }

    handleAlbumTitleInUnicodeChange = (event: any) => {

        let newValue = this.state.album ? this.state.album : {};
        newValue.titleInUnicode = event.target.value;

        this.setState({
            album: newValue
        })
    }

    handleSubmit = (event: any) => {

        event.preventDefault();

        const _this = this;

        const userid = CognitoService.getUserId();
        if(!userid) {
            alert('you need to login to upload');
            return;
        }
        
        this.setState({ loading: true });

        // get boolean value from string 'true' or 'false'
        let isPublic = JSON.parse(_this.state.public);

        axios.post(`${Consts.apiUrl}admin/update`, {
            id: _this.state.songid,
            title: _this.state.title,
            titleInUnicode: _this.state.titleInUnicode,
            titleInZawGyi: _this.state.titleInZawGyi,
            artists: _this.state.artists,
            album: _this.state.album,
            lyrics: _this.state.lyrics,
            link: _this.state.link,
            public: isPublic,
            genres: _this.state.genres,
            userid: userid,
            rating: JSON.parse(_this.state.rating)
          })
          .then(function (response: any) {
            _this.setState({ loading: false });
            // _this.props.history.push(`/song/edit/${_this.state.songid}`);
            window.location.reload();
          })
          .catch(function (error:any) {
            _this.setState({ loading: false });
            console.log(error);
          });
    }

    render() {
        let body = <div>
            <h1>Edit</h1>
                <Loading loading={this.state.loading}></Loading>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="title"
                            value={this.state.title}
                            onChange={this.handleChange}
                            placeholder="english or burglish title">
                        </input>
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="titleInUnicode"
                            value={this.state.titleInUnicode}
                            onChange={this.handleChange}
                            placeholder="unicode title">
                        </input>
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="titleInZawGyi"
                            value={this.state.titleInZawGyi}
                            onChange={this.handleChange}
                            placeholder="zawgyi title">
                        </input>
                    </div>
                    <div className="form-group">
                        <ArtistMultiSelect
                            options={this.state.artists}
                            onSelect={this.handleArtistsSelect}>
                        </ArtistMultiSelect>
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="album.titleInBurglish"
                            value={this.state.album?.titleInBurglish}
                            onChange={this.handleAlbumTitleInBurglishChange}
                            placeholder="album title in burglish">
                        </input>
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="album.titleInUnicode"
                            value={this.state.album?.titleInUnicode}
                            onChange={this.handleAlbumTitleInUnicodeChange}
                            placeholder="album title In Unicode">
                        </input>
                    </div>
                    <div className="form-group">
                        <GenresMultiSelect 
                            options={this.state.genres}
                            onSelect={this.handleGenresSelect}
                            >                            
                        </GenresMultiSelect>
                    </div>
                    <div className="form-group">
                        <a href='https://support.google.com/youtube/answer/171780?hl=en-GB' target='_blank'>click here</a> for how to get youtube embed link
                        <input
                            className="form-control"
                            type="text"
                            name="link"
                            value={this.state.link}
                            onChange={this.handleChange}
                            placeholder="Youtube embed link">
                        </input>
                    </div>

                    <div className="form-group">
                        <label>Lyrics as image</label>
                        {/* TODO: edit uploaded picture */}
                        {/* <input
                            className="form-control"
                            type="file"
                            name="file"
                        >
                        </input> */}
                        <div style={styles.image}>
                            <LyricsImage song={this.state.song}></LyricsImage>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Lyrics as texts</label>
                        <Styles>
                            {/* <Suspense fallback={<div>Loading...</div>}> */}
                                <CKEditor
                                    data={this.state.lyrics ? this.state.lyrics : '' }
                                    editor={ ClassicEditor }
                                    onInit={ (editor: any) => {
                                        // this.setState({ lyrics: '<p>lyrics</p>' });
                                        // You can store the "editor" and use when it is needed.
                                        // console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={ ( event: any, editor: { getData: () => any; } ) => {
                                        const data = editor.getData();
                                        // console.log( { event, editor, data } );
                                        this.setState({ lyrics: data });
                                    } }
                                    onBlur={ ( event: any, editor: any ) => {
                                        // console.log( 'Blur.', editor );
                                    } }
                                    onFocus={ ( event: any, editor: any ) => {
                                        // console.log( 'Focus.', editor );
                                    } }
                                >
                                </CKEditor>
                            {/* </Suspense> */}
                        </Styles>
                    </div>
                    <div className="form-group">
                        <select 
                            name="public"
                            value={this.state.public} 
                            onChange={this.handleChange}>
                            <option value='true'>public</option>
                            <option value='false'>private</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>rating</label>
                        <select 
                            name="rating"
                            value={this.state.rating} 
                            onChange={this.handleChange}>
                            <option>- select -</option>
                            <option value='5'>5</option>
                            <option value='4'>4</option>
                            <option value='3'>3</option>
                            <option value='2'>2</option>
                            <option value='1'>1</option>
                        </select>
                    </div>
                    <div className="form-group">
                        {this.state.errors}
                    </div>
                    
                    <input 
                        type="submit" 
                        value="Submit" 
                        className="btn btn-light" 
                    />
                </form>
        </div>

        return (
            <div style={styles.container}>
                {body}
            </div>
        )
    }
}

export default EditSong

const styles = {
    container: {
        width: '80%',
        padding: '20px',
    } as React.CSSProperties,
    image: {
        width: '200px',
    } as React.CSSProperties,
}