import * as React from 'react';
import Select from 'react-select';

const options = [
    { value: 'pop', label: 'Pop' },
    { value: 'rock', label: 'Rock' },
    { value: 'hiphop', label: 'Hip Hop' },    
    { value: 'alternative', label: 'Alternative' },
    { value: 'heavymetal', label: 'Heavy Metal' },
    { value: 'punk', label: 'Punk' },
    { value: 'country', label: 'Country' },
    { value: 'dance', label: 'Dance' },
    { value: 'electronic', label: 'Electronic' },
    { value: 'oldies', label: 'Oldies' },
];

class GenresMultiSelect extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            selectedOption: null,
        }
    }

    static getDerivedStateFromProps(props: any, state: any) {
        const preselectedValues = props.options;

        if(!preselectedValues) return null;

        const optionsValues = GenresMultiSelect.arrayToOptions(preselectedValues);

        if(optionsValues && optionsValues.length > 0) {

            return {
                selectedOption: optionsValues,
            }
        }

        return null;
    }

    static arrayToOptions(arrayOfStrings: string[]): any {

        let options: any = [];

        arrayOfStrings.forEach(function (item) {
            options.push({ value: item, label: GenresMultiSelect.capitalizeFirstLetter(item) });
        });

        return options;
    }

    static capitalizeFirstLetter(item: string): string {
        return item.charAt(0).toUpperCase() + item.slice(1);
    }

    handleChange = (selectedOption: any) => {
        // set the state so it will display correct value on the select
        this.setState(
            { selectedOption: selectedOption },
            // () => console.log(`Option selected:`, this.state.selectedOption)
        );

        // get genres out of selectedOption as string array
        let options: string[] = this.getStringArrayFromSelectedOptions(selectedOption);

        //this is to pass up to the parent component
        this.props.onSelect(options);
    }

    private getStringArrayFromSelectedOptions(selectedOption: any) {
        let options: string[] = [];
        selectedOption?.forEach((element: any) => {
            options.push(element.value);
        });
        return options;
    }

    render() {
        return (
            <div style={{color: 'hsl(0,0%,50%)'}}>
                <Select
                    isMulti
                    value={this.state.selectedOption}
                    onChange={this.handleChange}
                    options={options}
                    placeholder='genres'
                />
            </div>
        );
    }
}

export default GenresMultiSelect