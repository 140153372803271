import React, { Suspense } from 'react';
import * as Consts from '../services/constants';

import styled from 'styled-components';
import CognitoService from '../services/cognitoService';
import Loading from './loading';

const GenresMultiSelect = React.lazy(() => import('./genresMultiselect'));

const axios = require('axios').default;

const Styles = styled.div`
  color: black;
`

class ArtistEdit extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            id : props.match.params.id,
            errors: '',
            loading: false,
        }
    }

    componentDidMount() {
        const _this = this;
        if(this.state.id) {
            let queryUrl = `${Consts.apiUrl}admin/GetArtistById?id=${_this.state.id}`;
            fetch(queryUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    _this.setState({ ...result })
                }
            )
        }
    }

    handleChange = (event: any) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

    handleGenresSelect = (arg: any) => {
        const _this = this;
        _this.setState({genres: arg})
    }

    handleSubmit = (event: any) => {

        event.preventDefault();

        const _this = this;

        const userid = CognitoService.getUserId();
        if(!userid) {
            alert('you need to login to upload');
            return;
        }

        let error = false;
        error = this.checkerror(error);
        if(error) return;

        this.setState({ loading: true });

        axios.post(`${Consts.apiUrl}artist/update`, {
            id: _this.state.id,
            nameInBurglish: _this.state.nameInBurglish,
            nameInUnicode: _this.state.nameInUnicode,
            nameInZawgyi: _this.state.nameInZawgyi,
            type: _this.state.type,
            bio: _this.state.bio,
            genres: _this.state.genres,
            userCreated: userid,
          })
          .then(function (response: any) {
            _this.setState({ loading: false });
            _this.props.history.push(`/artist/edit/${_this.state.id}`);
          })
          .catch(function (error:any) {
            _this.setState({ loading: false });
            console.log(error);
          });
    }

    private checkerror(error: boolean) {
        const _this = this;
        if (!_this.state.nameInBurglish) {
            _this.setState({ errors: 'name In Burglish is needed' });
            error = true;
        }
        return error;
    }

    render() {
        return (
            <div >
                <h1>Edit artist</h1>

                <Loading loading={this.state.loading}></Loading>

                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="nameInBurglish"
                            value={this.state.nameInBurglish}
                            onChange={this.handleChange}
                            placeholder="burglish title">
                        </input>
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="nameInUnicode"
                            value={this.state.nameInUnicode}
                            onChange={this.handleChange}
                            placeholder="unicode title">
                        </input>
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="nameInZawgyi"
                            value={this.state.nameInZawgyi}
                            onChange={this.handleChange}
                            placeholder="zawgyi title">
                        </input>
                    </div>
                    <div className="form-group">
                        <select 
                            name="type"
                            value={this.state.type} 
                            onChange={this.handleChange}>
                            <option>- select -</option>
                            <option value='male'>male</option>
                            <option value='female'>female</option>
                            <option value='group'>group</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="bio"
                            value={this.state.bio}
                            onChange={this.handleChange}
                            placeholder="bio">
                        </input>
                    </div>
                    <div className="form-group">
                        <Suspense fallback={<div>Loading...</div>}>
                            <GenresMultiSelect 
                                options={this.state.genres}
                                onSelect={this.handleGenresSelect}
                            >                            
                            </GenresMultiSelect>
                        </Suspense>
                    </div>                  
                    <div className="form-group">
                        {this.state.errors}
                    </div>
                    
                    <input 
                        type="submit" 
                        value="Submit" 
                        className="btn btn-light" 
                    />
                </form>
            </div>
        );
    }
}

export default ArtistEdit