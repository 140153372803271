import * as React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import CognitoService from '../services/cognitoService';

import CreateSong from './createSong';
import SongList from './songlist';
import EditSong from './editsong';
import BulkUpload from './bulkupload';
import CreateArtist from './createArtist';
import ArtistList from './artistlist';
import ArtistEdit from './artistEdit';
import Searches from './searches';
import Login from './login';

import poolData from '../services/config';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

class Manage extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        const isAdmin = CognitoService.isAdmin();

        this.state = {
            title: {
                name: 'Admin page'
            },
            isAdmin: isAdmin
        }
        
        if(!isAdmin) {
            document.location.href="/login";
        }
    }

    componentDidMount() {
    }

    signOut = () => {
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();
        cognitoUser?.signOut();

        document.location.href="/";
    }

    render() {

        let adminpage = <div style={{paddingTop: 25 + 'px'}}>
                    <ul className="nav">
                        <li className="nav-item">
                            <a className="nav-link active" href={`/song/create`}>upload new song</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href={`/admin`}>manage songs</a>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link" href={`/song/createmany`}>bulk songs upload</a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link" href={`/artist/create`}>create new artist</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href={`/artist/list`}>manage artists</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href={`/searches`}>searches</a>
                        </li>
                        <li>
                        <button
                                    onClick={() => this.signOut()}
                                    className="btn btn-primary"
                                >Logout</button>
                        </li>
                    </ul>

                    <div style={styles.container}>
                    <BrowserRouter>
                        <Switch>
                            <Route path={`/song/create`} component={CreateSong}></Route>
                            <Route path={`/song/edit/:id`} component={EditSong}></Route>
                            {/* <Route path={`/song/createmany`} component={BulkUpload}></Route> */}
                            <Route path={`/artist/create`} component={CreateArtist}></Route>
                            <Route path={`/artist/list`} component={ArtistList}></Route>
                            <Route path={`/artist/edit/:id`} component={ArtistEdit}></Route>
                            <Route path={`/searches`} component={Searches}></Route>
                            <Route path={`/login`} component={Login}></Route>
                            {/* this route needs to be the last one */}
                            <Route path={`/`} component={SongList}></Route>
                        </Switch>
                    </BrowserRouter>
                    </div>
                </div>;

            const isAdmin = this.state.isAdmin;

            let body;

            if(isAdmin) {
                body = adminpage;
            }

        return (
            <div>
                {body}
            </div>            
        );
    }
}

export default Manage

const styles = {
    container: {
        width: '95%',
        padding: '10px',
    } as React.CSSProperties
}